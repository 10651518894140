<template>
	<div>
		<div class="main">
			<p class="font-italic pa-5">It seems you do not have permissions to any module.</p>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Homepage',


	data: () => ({
		drawer: false
	}),

	methods: {
		
	}
};
</script>

<style>

</style>
